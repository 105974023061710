/**
 * Add your config changes here.
 * @module config
 * @example
 * export const settings = {
 *   ...defaultSettings,
 *   port: 4300,
 *   listBlockTypes: {
 *     ...defaultSettings.listBlockTypes,
 *     'my-list-item',
 *   }
 * }
 */

import applyItaliaConfig from '@italia/config/italiaConfig';
import '@plone/volto/config';
import VirtualAssistant from '@package/components/VirtualAssistant/VirtualAssistant';

export default function applyConfig(voltoConfig) {
  let config = applyItaliaConfig(voltoConfig);

  config.settings = {
    ...config.settings,
    matomoSiteId: 'g7pYPPE3vM',
    matomoUrlBase: 'https://ingestion.webanalytics.italia.it/',
    siteProperties: {
      ...config.settings.siteProperties,
      siteTitle: "Camera di Commercio dell'Umbria",
      siteSubtitle: '',
      parentSiteTitle: 'Unioncamere',
      parentSiteURL: 'http://www.unioncamere.gov.it/',
      subsiteParentSiteTitle: "Camera di Commercio dell'Umbria",
    },
  };

  config.settings.italiaThemeViewsConfig.imagePosition = 'documentBody'; // possible values: afterHeader, documentBody

  config.settings['volto-blocks-widget'].allowedBlocks = [
    ...config.settings['volto-blocks-widget'].allowedBlocks,
    'listing',
  ];

  config.settings['volto-gdpr-privacy'] = {
    ...config.settings['volto-gdpr-privacy'],
    defaultPanelConfig: {
      ...config.settings['volto-gdpr-privacy'].defaultPanelConfig,
      last_updated: '2022-12-22T13:15:00+00:00',
      technical: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical,
        choices: [
          {
            config_key: 'MATOMO',
            text: {
              it: {
                title: 'Matomo',
                description:
                  "I cookie di Matomo sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
              },
              en: {
                title: 'Matomo',
                description:
                  'Matomo cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
              },
              fr: {
                title: 'Matomo',
                description:
                  "Les cookie de Matomo sont utilisés pour analyser la navigation sur le site afin de l'améliorer et offrir à l'utilisateur la meilleure expérience de navigation possible.",
              },
            },
          },
          /*...config.settings[
            'volto-gdpr-privacy'
          ].defaultPanelConfig.technical.choices.filter(
            (f) => f.config_key !== 'GANALYTICS',
          ),*/
        ],
      },
    },
  };
  config.settings.appExtras = [
    ...(config.settings.appExtras || []),
    {
      match: '',
      component: VirtualAssistant,
    },
  ];

  return config;
}
