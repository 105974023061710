import React, { useEffect } from 'react';

const VirtualAssistant = () => {
  useEffect(() => {
    const data = {
      'bot-id': '095f4473fbcf430cd968dcea1271e212',
      widget: 'true',
      open: 'false',
      'z-index': '9999',
      'welcome-back-suggestion': 'true',
      'hide-history': true,
      'cookie-expire': '24h',
    };
    const url = 'https://virtualassistant.alghoncloud.com/algho-viewer.min.js';

    const tag = document.createElement('algho-viewer');
    tag.setAttribute('id', 'algho-viewer');

    Object.keys(data).map(function (key) {
      tag.setAttribute(key, data[key]);
    });

    const script = document.createElement('script');
    script.setAttribute('id', 'algho-viewer-module');
    script.setAttribute('type', 'text/javascript');

    script.setAttribute('defer', 'defer');
    script.setAttribute('charset', 'UTF-8');
    script.setAttribute('src', url);

    setTimeout(function () {
      document.body.appendChild(tag);
      document.getElementsByTagName('body')[0].appendChild(script);
    }, 1000);

    // window.addEventListener('beforeunload', function () {
    //   algho.resetAll(false); //serve per fare in modo che quando si ricarica la pagina la chat si resetta
    // });

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(tag);
      // window.removeEventListener('beforeunload');
    };
  }, []);

  return <></>;
};

export default VirtualAssistant;
