import React from 'react';
import LogoSVG from '~/components/Layout/svg/logo.svg';

/*
 * Customization with image
 *
 * If you have a jpg/png, do like this:
 *
 * <figure className="icon">
 *  <img src={logo} alt="" />
 * </figure>
 *
 * Note the icon class.
 */
const LogoFooter = () => (
  <img
    src={LogoSVG}
    width="82"
    height="62"
    alt="Camera di Commercio dell'Umbria Logo"
    className="icon"
  />
);

export default LogoFooter;
